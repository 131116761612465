
import * as Li9za2lrby5tanM from './skiko.mjs';
import * as _ref_QGpzLWpvZGEvY29yZQ_ from '@js-joda/core';
import * as _ref_ZmlyZWJhc2UvYW5hbHl0aWNz_ from 'firebase/analytics';
import * as _ref_ZmlyZWJhc2UvYXBw_ from 'firebase/app';
import * as _ref_ZmlyZWJhc2UvYXV0aA_ from 'firebase/auth';
import * as _ref_ZmlyZWJhc2UvZmlyZXN0b3Jl_ from 'firebase/firestore';
import { instantiate } from './toontracker-android-composeApp-wasm-js.uninstantiated.mjs';


const exports = (await instantiate({
    './skiko.mjs': Li9za2lrby5tanM,
    '@js-joda/core': _ref_QGpzLWpvZGEvY29yZQ_,
    'firebase/analytics': _ref_ZmlyZWJhc2UvYW5hbHl0aWNz_,
    'firebase/app': _ref_ZmlyZWJhc2UvYXBw_,
    'firebase/auth': _ref_ZmlyZWJhc2UvYXV0aA_,
    'firebase/firestore': _ref_ZmlyZWJhc2UvZmlyZXN0b3Jl_
})).exports;

export const {
    main,
    memory,
    _initialize,
    startUnitTests
} = exports;

